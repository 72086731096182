.overviewSVG {
  height: 280px;
  width: 260px;
}

.infoBoxParagraph {
  font-size: 0.9rem;
  text-align: left;
}


