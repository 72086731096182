.checkout-item {
  width: 100%;
  display: flex;
  min-height: 100px;
  border-bottom: 1px solid darkgrey;
  padding: 15px 0;
  font-size: 20px;
  align-items: center;

  @media (max-width: 400px) {
    font-size: 14px;
  }

  .image-container {
    width: 23%;
    padding-right: 15px;

    img {
      width: 100%;
      height: 100%;
    }
  }
  .name,
  .quantity,
  .price {
    width: 30%;
    font-size: 16px;

    @media (max-width: 600px) {
      font-size: 12px;
    }

    &:nth-child(2) {
      width: 36%;

      @media screen and (max-width: 400px) {
        width: 32%;
      }
    }
    &:nth-child(3) {
      width: 17%;

      @media screen and (max-width: 400px) {
        width: 19%;
      }
    }
    &:nth-child(4) {
      width: 15%;
    }
    &:last-child {
      width: 12%;
      @media screen and (max-width: 400px) {
        width: 15%;
      }
    }
  }

  .quantity {
    display: flex;

    .arrow {
      cursor: pointer;
    }

    .value {
      margin: 0 10px;
    }
  }

  .remove-button {
    padding-left: 12px;
    cursor: pointer;
  }
}
