.success-checkout-main-header {
  text-align: center;
  color: #28a745;
}

.success-checkout-description {
  font-size: 14px;
  text-align: center;
  font-kerning: 20px;
  line-height: 25px;

  @media screen and (min-width: 800px) {
    font-size: 16px;
  }
}
