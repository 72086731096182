//autoprefixer & reset used
//Tested on Chrome, Firefox, IE11, and iOS.  The responsiveness & the social section is a bit off on IE11.  I'm looking to fix that.
//It would be best to break down the flex properties into logical classes.  I've actually done that more extensively on the live Pavilion site,  but I've only included one here: flex-rw.

.svg_div {
  :hover {
    color: #357ae8;
  }
}

.svg_icons {
  transform: scale(1.5);
  margin-right: 15px;
  margin-left: 15px;
  // margin-bottom: 0.3em;
}

$primary-light-blue: black;
$primary-line-color: black;
* {
  box-sizing: border-box;
}
html,
body {
  height: 100%;
}
body {
  // font: 11px "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: auto;
  display: flex;
  flex-flow: column nowrap;
  //This justify-content is essentially unnecessary in Firefox and Chrome due to the flex: 1 1 auto on the main.  However, it's needed to push the footer down on IE11
  justify-content: space-between;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
}
.generic-anchor {
  color: $primary-light-blue;
  &:visited {
    color: $primary-light-blue;
  }
  &:hover {
    color: $primary-line-color;
  }
}
.flex-rw {
  display: flex;
  flex-flow: row wrap;
}
//This main is mostly to push the footer to the bottom and for demo purposes.
main {
  flex: 1 1 auto; //For IE11
  display: flex;
  align-items: center;
  justify-content: center;
  font: 10em "Oswald", sans-serif;
  color: white;
  line-height: 1;
}
footer {
  background: white;
  margin-top: auto;
  width: 100%;
}

// Footer navigation
.footer-navigation {
  position: relative;
  z-index: 1;
  background: white;
  padding: 10px 25px;
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 1.2em;
  color: black;
  // cursor: pointer;
  // pointer-events: all;

  .footer-navigation::after {
    content: "";
    // padding
    position: absolute;
    z-index: 1;
    top: 50%;
    width: calc(100% - 20px);
  }

  :hover {
    color: #357ae8;
  }
}

//
.crossy {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.footer-list-top {
  width: 33.333%;
}
.footer-list-top > li {
  text-align: center;
  padding-bottom: 10px;
}
.footer-list-header {
  padding: 10px 0 5px 0;
  color: black;
  font: 2.3vw "Oswald", sans-serif;
}
.footer-list-anchor {
  font: 1.3em "Open Sans", sans-serif;
}
.footer-social-section {
  width: 100%;
  align-items: center;
  justify-content: space-around;
  position: relative;
  margin-top: 5px;
}

.footer-social-section::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 10px;
  border-top: 1px solid black;
  width: calc(100% - 20px);
}
.footer-social-overlap {
  position: relative;
  z-index: 2;
  background: white;
  padding: 0 20px;
}
.footer-social-connect {
  display: flex;
  align-items: center;
  font-size: 1.8em;
  color: black;
}
.footer-social-small {
  font-size: 0.6em;
  padding: 0px 10px;
}
.footer-social-overlap > a {
  font-size: 3em;
}
.footer-social-overlap > a:not(:first-child) {
  margin-left: 0.38em;
}
.footer-bottom-section {
  width: 100%;
  padding: 10px;
  border-top: 1px solid black;
  margin-top: 10px;
}
.footer-bottom-section > div:first-child {
  margin-right: auto;
}
.footer-bottom-wrapper {
  font-size: 1.5em;
  color: black;
}
.footer-address {
  display: inline;
  font-style: normal;
}

//
@media only screen and (max-width: 768px) {
  .footer-list-header {
    font-size: 2em;
  }
  .footer-list-anchor {
    font-size: 1.1em;
  }
  .footer-social-connect {
    font-size: 2.5em;
  }
  .footer-social-overlap > a {
    font-size: 2.24em;
  }
  .footer-bottom-wrapper {
    font-size: 1.3em;
  }
}

@media only screen and (max-width: 568px) {
  main {
    font-size: 5em;
  }
  .footer-list-top {
    width: 100%;
  }
  .footer-list-header {
    font-size: 3em;
  }
  .footer-list-anchor {
    font-size: 1.5em;
  }
  //
  .footer-navigation {
    justify-content: center;
    padding: 10px 10px;
  }
  .footer-social-section {
    justify-content: center;
  }
  .footer-social-section::after {
    top: 25%;
  }
  .footer-social-connect {
    margin-bottom: 10px;
    padding: 0 10px;
  }
  .footer-social-overlap {
    display: flex;
    justify-content: center;
  }
  .footer-social-icons-wrapper {
    width: 100%;
    padding: 0;
  }
  .footer-social-overlap > a:not(:first-child) {
    margin-left: 20px;
  }
  .footer-bottom-section {
    padding: 0 5px 10px 5px;
  }
  .footer-bottom-wrapper {
    text-align: center;
    width: 100%;
    margin-top: 10px;
  }
}
@media only screen and (max-width: 480px) {
  .footer-social-overlap > a {
    margin: auto;
  }
  .footer-social-overlap > a:not(:first-child) {
    margin-left: 0;
  }
  .footer-bottom-rights {
    display: block;
  }
}
@media only screen and (max-width: 320px) {
  .footer-list-header {
    font-size: 2.2em;
  }
  .footer-list-anchor {
    font-size: 1.2em;
  }
  .footer-social-connect {
    font-size: 2.4em;
  }
  .footer-social-overlap > a {
    font-size: 2.24em;
  }
  .footer-bottom-wrapper {
    font-size: 1.3em;
  }
}
