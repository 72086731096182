.cart-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 100px;
  height: 100px;
  -webkit-box-pack: center;
  -webkit-box-align: center;

  .shopping-icon {
    width: 45px;
    height: 45px;
  }
  .item-count {
    position: absolute;
    display: flex;
    font-size: 17px;
    font-weight: bold;
    bottom: 31px;
  }
}

.empty-cart-icon {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.2;
  width: 100px;
  height: 100%;
  cursor: not-allowed;

  .empty-shopping-icon {
    width: 45px;
    height: 45px;
  }
}
