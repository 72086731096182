.float {
	position: fixed;
	width: 60px;
	height: 60px;
	bottom: 40px;
	right: 40px;
	background-color: #25d366;
	color: #FFF;
	border-radius: 50px;
	text-align: center;
	font-size: 35px;
	box-shadow: 2px 2px 3px #999;
	z-index: 3;

	&:hover {
		background-color: black;
		color: white;
		// transition: all 0.3s
  //ox-shadow: 0px 15px 20px rgba(40, 176, 252, 99)
 }  //transform: translateY(-5px)

	&_further {
		margin-top: 13.5px; } }
