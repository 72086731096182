.cart-item {
  width: 100%;
  display: flex;
  height: 80px;
  margin-bottom: 15px;

  img {
    width: 30%;
  }

  .item-details {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 10px 20px;

    .name {
      font-size: 16px;
    }

    .price {
      margin-top: 7px;
    }

    @media screen and (max-width: 1000px) {
      .price {
        margin-top: 7px;
        margin-left: 0;
        margin-bottom: 0;
      }
    }

    @media screen and (min-width: 400px) {
      .price {
        margin-bottom: 0;
      }
    }
  }
}
