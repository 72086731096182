.checkout-page {
  min-height: 0vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 50px 0 0px 0;
  // transition: all 0.3s;

  @media screen and (min-width: 768px) {
    padding: 0 50px;
    // transition: all 0.3s;
  }

  @media screen and (min-width: 900px) {
    padding: 0 90px;
    // transition: all 0.3s;
  }

  @media screen and (min-width: 1300px) {
    padding: 0 200px;
    // transition: all 0.3s;
  }

  .checkout-header {
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid darkgrey;

    @media screen and (max-width: 500px) {
      font-size: 10px;
    }

    @media screen and (max-width: 700px) {
      font-size: 12px;
    }

    .header-block {
      text-transform: capitalize;
      width: 30%;

      &:nth-child(2) {
        width: 36%;
      }
      &:nth-child(3) {
        width: 19%;
        @media screen and (max-width: 400px) {
          width: 24%;
        }
      }
      &:nth-child(4) {
        width: 15%;
      }
      &:last-child {
        width: 12%;

        @media screen and (max-width: 700px) {
          width: 10%;
        }

        @media screen and (max-width: 400px) {
          width: 15%;
        }
      }
    }
  }

  .total {
    margin-top: 30px;
    margin-left: auto;
    font-size: 25px;

    @media screen and (max-width: 400px) {
      font-size: 20px;
    }
  }

  //@at-root
  &_order {
    // width: 80% !important;
  }
}

.customForm-inner {
  padding: 10px 50px;
}

// SweetAlert buttons
.swal-modal .swal-text {
  text-align: center;
}

.swal-button--openForm {
  background-color: #25d366;
  // color: black;
}

.swal-button.swal-button--cancel {
  // background-color: #e64942;
  // color: white;
  background-color: #efefef;
  &:hover {
    color: #fff;
  }
  // color: black;
}

.hoverEffect:not([disabled]):hover {
  background-color: black;
}

.cancelEffect:not([disabled]):hover {
  // background-color: #e64942;
  // color: black;
}

.doubleConfirmEffect {
  background-color: #25d366;
}

.doubleConfirmEffect:not([disabled]):hover {
  background-color: black;
  // color: black;
}

//
.swal-button {
  background-color: #25d366;

  &:not([disabled]):hover {
    background-color: black;
  }
}

.open-form-header {
  font-size: 17px;

  @media screen and (min-width: 500px) {
    font-size: 25px;
  }
}
