.options {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* All of the bubble choices  */
.option {
  font-weight: bold;
  border-radius: 16px;
  padding: 11px;
  border: 0.07rem solid black;
  color: #1d1d1d;
  font-size: 0.9rem;
  margin: 4px 2px 4px 4px;
  background: transparent;
}

/* Hover effect for options */
.option:hover {
  background-color: #357ae8;
  border-color: #357ae8;
  color: white;
}

/* Disable focus */
.option:focus {
  outline: none;
  /* cursor: not-allowed; */
}