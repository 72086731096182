body {
  font-family: "Noto Sans", sans-serif;
  padding: 20px 60px;
  // background: ;
}

a {
}

* {
  box-sizing: border-box;
}

.App {
  /* text-align: center; */
}

/*  */

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*  */

.app-npm-install {
  background: #286f65;
  padding: 8px;
  border-radius: 3px;
  color: #fff;
  width: 250px;
  margin: 30px auto;
}

p,
li {
  line-height: 1.5;
  margin: 1rem 0;
}

.app-header {
  color: #fff;
}

.app-paragraph {
  color: black;
  /* color: #fff; */
  max-width: 400px;
  margin: 0 auto;
}

.app-overview {
  margin-top: 5rem;
}

.app-overview-gist-container {
  max-width: 500px;
  margin: 2rem auto;
}

.app-gists-link {
  background-color: #53a88b;
  color: #fff;
  padding: 10px;
  border-radius: 3px;
  text-decoration: none;
  width: 250px;
  font-size: 1.1rem;
  margin: 0.5rem 0;
}

.app-gists-link-dark {
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  padding: 10px;
  border-radius: 3px;
  text-decoration: none;
  width: 250px;
  font-size: 1.1rem;
  margin: 0.5rem 0;
  // transition: background-color 0.4s ease;

  &:hover {
    background-color: #fff;
    color: rgb(51, 96, 124);
  }
}

.gist {
  max-width: 100%;
}

.app-quickstart-button {
  color: #fff;
  border: 1px solid #fff;
  text-decoration: none;
  padding: 10px;
  display: block;
  margin: 30px auto;
  width: 150px;
}
