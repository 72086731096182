.logo {
  grid-column: 1/2;
  align-self: center;
  z-index: -1;
  // transition: all 0.3s;

  svg {
    height: 80px;
    width: auto;
    @media screen and (min-width: 1000px) {
      height: 70%;
      width: auto;
    }
  }
}
