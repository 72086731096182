.app-chatbot-container {
  margin: 40px 0;
  display: flex;
  /* justify-content: center; */
  position: fixed;
  right: 120px;
  bottom: 67px;
  z-index: 9999;
  box-shadow: 5px 5px 13px rgba(91, 81, 81, 0.4);
  border-radius: 5px;
  /* background-color: black; */
}

.react-chatbot-kit-chat-input {
  font-size: 0.8rem;
}

.react-chatbot-kit-chat-header {
  font-size: 1rem;
}

.app-chatbot-button {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  background-color: black;
  border: none;
  position: fixed;
  bottom: 40px;
  z-index: 3;
  right: 120px;
  box-shadow: 1px 8px 6px -6px #999;
  
  cursor: pointer;
  outline: none;

  &:focus {
    outline: none;
  }
}



/* .react-chatbot-kit-chat-inner-container {
  height: 700px;
  background-color: #fff;
  border-radius: 3px;
  margin-bottom: 15px;
  border-radius: 5px;
} */

.react-chatbot-kit-user-chat-message {
  background-color: #e8e8eb;
  padding: 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  font-weight: medium;
  position: relative;
  text-align: left;

  line-height: 1.3;
  color: black;
  font-size: 1rem;
}

.app-chatbot-button-icon {
  width: 30px;
  height: 30px;
  fill: #fff;
  /* background-image: url('./logo.svg'); */
}

.app-chatbot-button {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  background-color: #357ae8;
  border: none;
  position: fixed;
  bottom: 40px;
  z-index: 3;
  right: 120px;
  box-shadow: 2px 2px 3px #999;
  cursor: pointer;
  outline: 0;
  // transition: all 0.3s;

  &:active {
    background-color: #357ae8;
    box-shadow: 0px 15px 20px rgba(40, 176, 252, 99);
    color: #fff;
  }

  @media (min-width: 990px) {
    &:hover {
      // transition: all 0.3s;
      background-color: black;
      box-shadow: 0px 15px 20px rgba(40, 176, 252, 99);
      color: #fff;
      transform: translateY(-5px);
    }
  }

  /*
   * https://codepen.io/ericwbailey/pen/KQOpRM
   * after, active:after
   */
}

.react-chatbot-kit-chat-container {
  /* Size of whole chatbot container */
  height: 700px !important;
  width: 380px !important;
}

.react-chatbot-kit-chat-inner-container {
  height: 700px !important;
  background-color: #fff;
  border-radius: 3px;
  margin-bottom: 15px;
  border-radius: 5px;
}

.react-chatbot-kit-chat-bot-message {
  // font-family: 'Noto Sans', sans-serif;
  line-height: 1.3;
  // color: black;
  width: 260px !important;
  font-size: 1rem;
  margin-right: 40px;
}

@media (max-width: 990px) {
  .react-chatbot-kit-chat-inner-container {
    height: 700px !important;
  }
}

.react-chatbot-kit-chat-message-container {
  padding: 0 13px 10px 13px;
  margin: 10px 0 0 0;
  height: 600px !important;
}

@media (max-width: 800px) {
  .app-chatbot-container {
    transform: scale(0.9) translateX(20px) translateY(35px);
  }

  .react-chatbot-kit-chat-input {
    font-size: 1.1rem;
  }

  .react-chatbot-kit-chat-header {
    font-size: 1.1rem;
  }
}

@media (max-width: 500px) {
  .app-chatbot-container {
    transform: scale(0.8) translateX(140px) translateY(80px);
  }
}