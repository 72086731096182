.nav-color {
  background-color: white !important;
}

.navbar-toggler {
  color: red;
}

.navbar-nav {
  align-items: center;
  border-color: black;
}

@media only screen and (max-width: 991px) {
  .navbar-nav {
    border-style: dotted;
  }
}

.navbar-bar {
  background-color: antiquewhite;
}

/* .navbar-collapse {
} */

.blog-class {
  color: rgba(0, 0, 0, 0.5);
}

.blog-class:hover {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.9);
}

.active {
  font-weight: 700;
  text-decoration: none;
}
