

.TA_certificateOfExcellence {
  
  .widCOEImgNav {
    grid-column: 2;
    align-self: center;
    position: relative;
    // display: flex;
    align-items: center;
    justify-content: center;
    width: 110px;
    padding-top: 20px;
    // transition: all 0.3s;
    display: none;

    @media screen and (max-width: 600px) {
      display: inline;
      width: 150px;
      // &:hover {
      //   transform: scale(1.1);
      // }
    }
  }

  .widCOEImgChat {
    // height: 70px;
    // grid-column: 2;
    // align-self: center;
    // z-index: 999;
    grid-column: 2;
    align-self: center;
    position: relative;
    // display: flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    padding-top: 4px;
    // transition: all 0.3s;

    // @media screen and (max-width: 990px) {

    //   &:hover {
    //     transition: all 0.3s;
    //     transform: scale(1.1);
    //     // align-self: center;
    //   }
    // }
  }
}
